/* die credentials sind für die jeweiligen Workspaces Konfiguriert

quiz-intern.techstarter.academy:
techstarter.de -> 456762920757-0cccduarsajuqvgop6el1loikfu81jqj.apps.googleusercontent.com

quiz.techstarter.academy:
edu.techstarter.de -> 931590533016-9kjs75p3vosg9ti0euivcsifeopt0nip.apps.googleusercontent.com

es muss jeweils die richtige GoogleClientId verwendet werden bei dem deployment
*/

// export const GoogleClientId = "456762920757-0cccduarsajuqvgop6el1loikfu81jqj.apps.googleusercontent.com";  // techstarter.de
// export const GoogleClientId = "931590533016-9kjs75p3vosg9ti0euivcsifeopt0nip.apps.googleusercontent.com";  // edu.techstarter.de
export const GoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export enum APP {
  AWS_CP = "aws-cp",
  AWS_SA = "aws-sa",
  LPIC_LINUX_ESSENTIALS = "lpic-linux-essentials",
  SCRUM_PSM_1 = "scrum-psm1"
}

export enum LANG {
  DE = "de",
  EN = "en"
}
